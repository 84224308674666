import React from 'react';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

type PropsWithChildren<P> = P & {
    children?: React.ReactNode;
    onNavigate?: VoidFunction;
};

export default function BackButtonLayout({ children, onNavigate }: PropsWithChildren<object>) {
    const navigate = useNavigate();

    const handleGoBack = () => {
        onNavigate && onNavigate();
        navigate('/', { replace: true });
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box
                sx={{
                    marginTop: '1rem',
                    textAlign: 'center',
                    pb: 3,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '20px',
                    }}
                >
                    {children}
                </Box>

                {/* Retry button */}
                <Button onClick={handleGoBack} variant='contained'>
                    Go back
                </Button>
            </Box>
        </Box>
    );
}
