import * as React from 'react';
import { Container } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import Home from '../pages/Home';
import { routerLinks } from '../router/router';
import { DigitalLivestock } from '../pages/DigitalLivestock';
import { DigitalLivestockConsignment } from '../pages/DigitalLivestockConsignment';
import { NationalVendorDeclaration } from '../pages/NationalVendorDeclaration';
import SellCattle from '../pages/SellCattle';

/**
 * Components object is used to map the component name in routerLinks function to the component itself.
 */
const components: Record<string, React.FC> = {
    DigitalLivestock: DigitalLivestock,
    DigitalLivestockConsignment: DigitalLivestockConsignment,
    NationalVendorDeclaration: NationalVendorDeclaration,
    SellCattle: SellCattle,
};

/**
 * Layout component is used to display the header and navigation to other pages
 */
const Layout = () => {
    return (
        <Container
            sx={{
                mt: '64px',
                mb: '24px',
            }}
        >
            <Routes>
                <Route path='/' element={<Home />} />
                {routerLinks.map((page) => (
                    <Route
                        key={page.title}
                        path={page.path}
                        element={React.createElement(components[page.component])}
                    />
                ))}
            </Routes>
        </Container>
    );
};

export default Layout;
