import { createTheme, Theme } from '@mui/material/styles';

const theme: Theme = createTheme({
    palette: {
        primary: {
            main: '#87ceeb',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#ffffff',
            contrastText: '#000000',
        },
    },
    typography: {
        fontFamily: 'Roboto, sans-serif',
    },
});

export default theme;
